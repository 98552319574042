import React from "react";
import { connect } from "react-redux";
import Layout from "../components/layout/layout";
import OrderList from "../components/cart/orderList/list";
import PriceInfo from "../components/cart/priceInfo/price";
import UtensilsSelector from "../components/cart/priceInfo/UtensilsSelector";
import OrderType from "../components/cart/priceInfo/pickupOrDelivery";
import NoItemsInCart from "../components/layout/noItemsInCart";

const cart = props => {
  const { orderLen } = props;
  return (
    <Layout>
      <div
        style={{ backgroundColor: "rgb(252,252,252)", marginBottom: "-8px" }}
      >
        <br />
        <h1>Cart</h1>
        <div style={{ width: "88%", margin: "auto" }}>
          <h2>COVID-19 Update:</h2>
          <h3>
            Out of concern for the health of our customers and staff, we will
            follow these policies.
          </h3>
          <div style={{ textAlign: "left" }}>
            1. Due to understaffing from COVID-19, we will be closed every
            Tuesday until further notice.
            <br />
            2. When we deliver your order, we will drop it off at your door and
            give you a call.
            <br />
            3. For apartments, please come down to the front gate to pick up
            your food.
            <br />
            4. Please enter your tip on this screen before you checkout, thank
            you!
          </div>
          <br />
        </div>
        {orderLen > 0 ? (
          <div>
            <br />
            <div style={{ width: "91.9%", margin: "auto" }}>
              <OrderList />
              <div>
                <OrderType />
                <UtensilsSelector />
                <PriceInfo />
              </div>
            </div>
            <br />
          </div>
        ) : (
          <NoItemsInCart />
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  orderLen: state.order.items.length,
});

export default connect(mapStateToProps)(cart);
