import React from "react";
import TextField from "@material-ui/core/TextField";

export default function sauces(props) {
  const { handleChange } = props;
  const {
    eelSauce,
    spicyMayo,
    teriyakiSauce,
    bourbonSauce,
    sweetSourSauce,
    dumplingSauce
  } = props.sauces;
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h3>Sauces</h3>
        <p>Enter Numbers Only!</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around"
        }}
      >
        <TextField
          label="Eel Sauce"
          type="number"
          onChange={handleChange("eelSauce")}
          value={eelSauce.number}
          inputProps={{ min: "0" }}
        />
        <TextField
          label="Spicy Mayo"
          type="number"
          onChange={handleChange("spicyMayo")}
          value={spicyMayo.number}
          inputProps={{ min: "0" }}
        />
        <TextField
          label="Teriyaki Sauce"
          type="number"
          onChange={handleChange("teriyakiSauce")}
          value={teriyakiSauce.number}
          inputProps={{ min: "0" }}
        />
        <TextField
          label="Bourbon Sauce"
          type="number"
          onChange={handleChange("bourbonSauce")}
          value={bourbonSauce.number}
          inputProps={{ min: "0" }}
        />
        <TextField
          label="Sweet & Sour Sauce"
          type="number"
          onChange={handleChange("sweetSourSauce")}
          value={sweetSourSauce.number}
          inputProps={{ min: "0" }}
        />
        <TextField
          label="Dumpling Sauce"
          type="number"
          onChange={handleChange("dumplingSauce")}
          value={dumplingSauce.number}
          inputProps={{ min: "0" }}
        />
      </div>
    </div>
  );
}
