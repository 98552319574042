import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

export default function specialRequests(props) {
  const { requests, handleChange } = props;
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Add Requests</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around"
        }}
      >
        <TextField
          style={{ width: "86.4%" }}
          value={requests}
          onChange={handleChange}
          helperText="Requests could change final price of item. We will call you if it does."
        />
      </div>
    </div>
  );
}

specialRequests.propTypes = {
  requests: PropTypes.string
};

specialRequests.defaultProps = {
  requests: ""
};
