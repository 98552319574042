import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

export default function sideChoiceDialog(props) {
  const { handleChange, sideChoice, category } = props;
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Side Choice</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <FormControl>
          {category === "Combo" ? (
            <Select
              autoWidth
              inputProps={{
                name: "Side Choice",
                id: "Side Choice",
              }}
              onChange={handleChange}
              value={sideChoice}
            >
              <MenuItem value="Pork Egg Roll">Pork Egg Roll</MenuItem>
              <MenuItem value="Spring Roll">Spring Roll</MenuItem>
              <MenuItem value="Coke">Coke</MenuItem>
              <MenuItem value="Diet Coke">Diet Coke</MenuItem>
              <MenuItem value="Mountain Dew">Mountain Dew</MenuItem>
              <MenuItem value="Sprite">Sprite</MenuItem>
              <MenuItem value="Water">Water</MenuItem>
            </Select>
          ) : (
            <Select
              autoWidth
              inputProps={{
                name: "Side Choice",
                id: "Side Choice",
              }}
              onChange={handleChange}
              value={sideChoice}
            >
              <MenuItem value="Seaweed Salad">Seaweed Salad</MenuItem>
              <MenuItem value="Miso Soup">Miso Soup</MenuItem>
            </Select>
          )}
        </FormControl>
      </div>
    </div>
  );
}
