import React, { Component } from "react";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../state/mapStateAndDispatch/cart";

class UtensilsSelector extends Component {
  handleChange = event => {
    const { value } = event.target;
    const { setWithUtensils } = this.props;
    setWithUtensils(value);
  };

  render() {
    const { withUtensils } = this.props;
    return (
      <form>
        <p style={{ textAlign: "left" }}>Need Utensils?</p>
        <RadioGroup
          aria-label="Need Utensils?"
          name="Need Utensils?"
          value={withUtensils}
          onChange={this.handleChange}
        >
          <FormControlLabel
            value="No"
            control={<Radio color="primary" />}
            label="No"
          />
          <FormControlLabel
            value="Yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
        </RadioGroup>
      </form>
    );
  }
}

UtensilsSelector.propTypes = {
  withUtensils: PropTypes.string.isRequired,
  setWithUtensils: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UtensilsSelector);
