import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import rnChoices from "../../menu/rnChoices";

export default function rnChoiceDialog(props) {
  const { handleChange, rnChoice } = props;
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Rice / Noodle Choice</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <FormControl>
          <Select
            autoWidth
            inputProps={{
              name: "age",
              id: "age-simple",
            }}
            onChange={handleChange}
            value={rnChoice}
          >
            {rnChoices.map(choice => {
              const { name, price } = choice;
              const displayString = `${name} (+${price.toFixed(2)})`;
              return (
                <MenuItem key={name} value={name}>
                  {displayString}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
