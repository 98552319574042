import React from "react";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

export default function meatUpgradesDialog(props) {
  const { handleChange, meatChoice } = props;
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Meat Upgrades</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <FormControl>
          <Select
            autoWidth
            inputProps={{
              name: "meat",
              id: "meat",
            }}
            onChange={handleChange}
            value={meatChoice}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="Extra Meat">Extra Meat (+$2.50)</MenuItem>
            <MenuItem value="(2) Extra Meat">(2) Extra Meat (+$5.00)</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

meatUpgradesDialog.propTypes = {
  handleChange: PropTypes.func.isRequired,
  meatChoice: PropTypes.string,
};

meatUpgradesDialog.defaultProps = {
  meatChoice: "None",
};
