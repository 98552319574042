import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/buttons.css";
import SauceChoice from "./sauces";
import RNChoice from "./rnChoice";
import Requests from "./requests";
import SideChoice from "./sideChoice";
import checkSideCategory from "./checkSideCategory";
import checkMeatChoice from "./checkMeatChoice";
import MeatChoice from "./meatChoice";
import rnChoices from "../../menu/rnChoices";

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    const {
      sauces,
      requests,
      rnChoice,
      category,
      sideChoice,
      meatChoice,
      rnPrice,
      meatPrice,
    } = this.props;
    this.state = {
      open: false,
      sauces,
      requests,
      rnChoice,
      category,
      sideChoice,
      meatChoice,
      rnPrice,
      meatPrice,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const {
      setSauces,
      num,
      setRequests,
      updateRN,
      updateSide,
      updateMeat,
    } = this.props;
    const {
      sauces,
      requests,
      rnChoice,
      rnPrice,
      sideChoice,
      meatChoice,
      meatPrice,
    } = this.state;
    this.setState({ open: false }, () => {
      setSauces(sauces, num);
      setRequests(requests, num);
      updateRN(rnChoice, rnPrice, num);
      updateSide(sideChoice, num);
      updateMeat(meatChoice, meatPrice, num);
    });
  };

  handleSauceChange = name => event => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    let priceMultiplier = 0.5;
    if (name === "teriyakiSauce" || name === "bourbonSauce") {
      priceMultiplier = 1;
    }
    this.setState({
      sauces: {
        ...this.state.sauces,
        [name]: {
          number: onlyNums,
          price: onlyNums * priceMultiplier,
        },
      },
    });
  };

  handleRequestChange = event => {
    this.setState({
      requests: event.target.value,
    });
  };

  handleRNChange = event => {
    const { value } = event.target;
    const item = rnChoices.find(element => element.name === value);
    this.setState({
      rnChoice: item.name,
      rnPrice: item.price,
    });
  };

  handleSideChange = event => {
    const { value } = event.target;
    this.setState({
      sideChoice: value,
    });
  };

  handleMeatChange = event => {
    const { value } = event.target;
    if (value.match("Extra Meat")) {
      this.setState({
        meatChoice: value,
        meatPrice: 2.5,
      });
    } else if (value.match("(2) Extra Meat")) {
      this.setState({
        meatChoice: value,
        meatPrice: 5,
      });
    } else {
      this.setState({
        meatChoice: value,
        meatPrice: 0,
      });
    }
  };

  render() {
    const {
      open,
      sauces,
      requests,
      rnChoice,
      category,
      sideChoice,
      meatChoice,
    } = this.state;
    return (
      <div>
        <a
          className="blueButton"
          style={{ letterSpacing: 1.01 }}
          onClick={this.handleOpen}
          onKeyDown={this.handleOpen}
        >
          Edit
        </a>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Customize Item</DialogTitle>
          <DialogContent>
            <SauceChoice
              handleChange={this.handleSauceChange}
              sauces={sauces}
            />
            <br />
            <Requests
              requests={requests}
              handleChange={this.handleRequestChange}
            />
            <br />
            {category === "Combo" && (
              <RNChoice
                rnChoice={rnChoice}
                handleChange={this.handleRNChange}
              />
            )}
            {checkSideCategory(category) && (
              <SideChoice
                category={category}
                handleChange={this.handleSideChange}
                sideChoice={sideChoice}
              />
            )}
            {checkMeatChoice(category) && (
              <MeatChoice
                handleChange={this.handleMeatChange}
                meatChoice={meatChoice}
              />
            )}
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <a className="blueButton" onClick={this.handleClose} role="button">
              Save
            </a>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  requests: PropTypes.string,
  setSauces: PropTypes.func.isRequired,
  num: PropTypes.number.isRequired,
  sauces: PropTypes.instanceOf(Object).isRequired,
  setRequests: PropTypes.func.isRequired,
  rnChoice: PropTypes.string.isRequired,
  updateRN: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  sideChoice: PropTypes.string,
  updateSide: PropTypes.func.isRequired,
  updateMeat: PropTypes.func.isRequired,
  meatChoice: PropTypes.string,
  rnPrice: PropTypes.number,
  meatPrice: PropTypes.number,
};

AlertDialog.defaultProps = {
  requests: "",
  sideChoice: "",
  meatChoice: "None",
  rnPrice: 0,
  meatPrice: 0,
};

export default AlertDialog;
