import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../state/mapStateAndDispatch/cart";
import "../../../styles/buttons.css";
import CustomizeItem from "../customizeItem/CustomizeItem";
import returnSauceName from "./returnSauceName";

const nanoid = require("nanoid");

class List extends Component {
  render() {
    const {
      order,
      deleteOrder,
      clearAllItems,
      setSauces,
      setRequests,
      updateRN,
      updateSide,
      updateMeat,
    } = this.props;
    const list = order.map((item, index) => {
      const finalPrice =
        parseFloat(item.price) +
        (parseFloat(item.rnPrice) || 0) +
        (parseFloat(item.meatPrice) || 0) +
        parseFloat(item.sauces.eelSauce.price) +
        parseFloat(item.sauces.spicyMayo.price) +
        parseFloat(item.sauces.teriyakiSauce.price) +
        parseFloat(item.sauces.bourbonSauce.price) +
        parseFloat(item.sauces.sweetSourSauce.price) +
        parseFloat(item.sauces.dumplingSauce.price);
      function checkRNPrice(val) {
        if (val) {
          if (val > 0) {
            return true;
          }
        }
        return false;
      }
      function checkMeat(meatChoice) {
        if (meatChoice) {
          if (meatChoice !== "None") {
            return true;
          }
        }
        return false;
      }
      return (
        <Grid
          container
          style={{ textAlign: "left" }}
          key={nanoid()}
          alignItems="center"
        >
          <Grid item sm={8} xs={6}>
            <p>{item.name}</p>
            <ul style={{ listStyleType: "none" }}>
              {item.sideChoice && (
                <li>
                  <b>Side:</b> {item.sideChoice}
                </li>
              )}
              {item.rnChoice && (
                <li>
                  <b>Rice/Noodle:</b> {item.rnChoice}
                  {checkRNPrice(item.rnPrice) && (
                    <span>{` (+$${item.rnPrice.toFixed(2)})`}</span>
                  )}
                </li>
              )}
              {checkMeat(item.meatChoice) && (
                <li>
                  <b>Meat Choice:</b> {item.meatChoice}{" "}
                  <span>{` (+$${item.meatPrice.toFixed(2)})`}</span>
                </li>
              )}
              {item.requests && (
                <li>
                  <b>Requests:</b> {item.requests}
                </li>
              )}
              {Object.keys(item.sauces).filter(
                key => item.sauces[key].number > 0
              ).length > 0 && (
                <div>
                  <b>
                    <li>Sauces:</li>
                  </b>
                  {Object.keys(item.sauces)
                    .filter(key => item.sauces[key].number > 0)
                    .map(key => (
                      <li>
                        {`${returnSauceName(key)} ` +
                          `x${item.sauces[key].number} (+$${item.sauces[
                            key
                          ].price.toFixed(2)})`}
                      </li>
                    ))}
                </div>
              )}
            </ul>
          </Grid>
          <Grid
            item
            sm={2}
            xs={3}
            style={{ display: "flex", alignItems: "center " }}
          >
            <CustomizeItem
              num={index}
              category={item.category}
              sauces={item.sauces}
              setSauces={setSauces}
              requests={item.requests}
              setRequests={setRequests}
              rnChoice={item.rnChoice}
              updateRN={updateRN}
              rnPrice={item.rnPrice}
              sideChoice={item.sideChoice}
              updateSide={updateSide}
              meatChoice={item.meatChoice}
              meatPrice={item.meatPrice}
              updateMeat={updateMeat}
            />
          </Grid>
          <Grid item sm={2} style={{ textAlign: "right" }} xs={3}>
            <p>
              ${finalPrice.toFixed(2)}&nbsp;&nbsp;
              <span
                style={{
                  color: "#F50057",
                  cursor: "pointer",
                  fontSize: "1.05em",
                }}
                onClick={() => deleteOrder(index)}
                role="button"
                tabIndex={0}
                onKeyUp={() => deleteOrder(index)}
              >
                X
              </span>
            </p>
          </Grid>
        </Grid>
      );
    });
    return (
      <div style={{ textAlign: "left" }}>
        {list}
        <br />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            className="bigRedButton"
            onClick={clearAllItems}
            role="button"
            tabIndex={0}
            onKeyUp={clearAllItems}
          >
            Clear Order
          </a>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

List.propTypes = {
  order: PropTypes.instanceOf(Array).isRequired,
  deleteOrder: PropTypes.func.isRequired,
  setSauces: PropTypes.func.isRequired,
  clearAllItems: PropTypes.func.isRequired,
  setRequests: PropTypes.func.isRequired,
  updateRN: PropTypes.func.isRequired,
  updateSide: PropTypes.func.isRequired,
  updateMeat: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
